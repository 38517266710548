.drop-container
    position: fixed
    display: flex
    padding: 16px
    flex-direction: column
    align-items: flex-start
    gap: 24px
    border-radius: 8px
    background: white
    box-shadow: 0px 32px 56px 0px rgba(0, 0, 0, 0.10)
    width: 380px
    z-index: 4
    top: 0
    opacity: 0
    visibility: hidden
    transition: opacity .3s, visible 0s .3s

    .triangle
        position: absolute
        bottom: 100%
        left: 16px
        width: 20px
        height: 12px

    &.showed
        opacity: 1
        visibility: visible
        transition: opacity .3s, visible 0s

    .body
        width: 100%
        max-height: 312px
        overflow-y: auto
        overflow-x: hidden

    .footer
        display: flex
        justify-content: space-between
        align-items: center
        width: 100%
        gap: 8px

        > *
            flex: 1

        .btn
            --bs-btn-padding-x: 12px
            --bs-btn-padding-y: 12px
    
    .filter-content

        .fields

            .field
                display: flex
                padding: 16px 8px
                align-items: center
                gap: 8px
                justify-content: space-between
                font-size: 13px
                line-height: 25px
                color: #555
                border-bottom: 1px solid #eee
                cursor: pointer

                &:last-child
                    border-bottom: none

@media (max-width: 1550px)
    .drop-container
        .footer
            .btn
                --bs-btn-font-size: 13px

@media (max-width: 1020px)
    .drop-container
        --drop-pad: 40px
        width: 100%
        height: 100%
        top: 0 !important
        left: 0 !important
        padding: 0

        .triangle
            display: none

        .header
            padding: 16px var(--drop-pad)
            font-size: 15px
            font-weight: 600
            line-height: 150%
            color: #555
            display: flex
            justify-content: space-between
            align-items: center
            border-bottom: 1px solid #EEE
            width: 100%

            .close
                width: 16px
                height: 16px
                stroke: #999

            .header-internal
                display: flex
                align-items: center
                gap: 8px

        .body
            padding: 0 var(--drop-pad)
            max-height: 100%
            flex: 1

        .footer
            border-top: 1px solid #EEE
            padding: 16px var(--drop-pad)
            opacity: 0
            visibility: hidden
            transition: opacity .3s, visible 0s .3s

        &.internal
            .footer
                opacity: 1
                visibility: visible
                transition: opacity .3s, visible 0s

@media (max-width: 575px)
    .drop-container
        --drop-pad: 16px