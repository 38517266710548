.logo
    display: flex
    gap: 32px
    color: #555
    align-items: center

    img
        width: 223px

    span
        font-size: 14px
        line-height: 140%
    
    &.big
        flex-direction: column
        align-items: flex-start
        color: #333
        max-width: 100%
        width: 590px

        img
            width: 314px

        span
            font-size: 56px
            font-weight: 600
            line-height: 120%

@media (max-width: 1550px)
    .logo
        &.head
            gap: 16px

            img
                width: 160px

            span
                font-size: 13px

@media (max-width: 1280px)
    .logo
        &.big
            span
                font-size: 48px

@media (max-width: 991px)
    .logo
        &.big
            gap: 16px

            img
                width: 200px

            span
                font-size: 24px
                line-height: 150%

                br:first-child
                    display: none