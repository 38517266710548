.form-label
    margin-bottom: 4px
    color: #999

.form-control
    padding: 8px 16px
    border-radius: 3px
    border: 1px solid #EEE
    background: #F8F8F8
    color: #555
    font-size: 14px
    line-height: 30px
    transition: background .2s, border-color .2s

    &:hover
        background: white

    &:focus, &:focus-visible
        background: white
        border-color: #666
        box-shadow: none
        color: #555

.form-control.is-invalid
    background-image: none

    &:focus
        box-shadow: none

.invalid-tooltip
    left: 50%
    transform: translateX(-50%)
    margin-top: 3px

.form-input-wrapper
    position: relative

    &:hover
        .form-control
            background: white

    .show-pass
        position: absolute
        top: 12px
        right: 16px
        width: 24px
        height: 24px
        cursor: pointer
        background: url(/imgs/eye.svg)
        transition: background .2s

    &.showed
        
        .show-pass
            background: url(/imgs/no-eye.svg)

.form-buttons
    display: flex
    justify-content: space-between
    align-items: center
    padding-top: 32px

@media (max-width: 1280px)
    .form-label
        font-size: 14px