.modal-contacts, .modal-user

    .modal-body
        display: flex
        flex-direction: column
        align-items: center
        padding-bottom: 63px

        .logo
            margin: 8px 0 32px
            padding: 16px
            gap: 16px
            border-radius: 4px
            background: white
            box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.05)

            img
                width: 160px

            span
                font-size: 12px

        .contacts
            display: flex
            flex-direction: column
            gap: 16px

            .phone, .email
                font-size: 13px
                line-height: 25px
                padding-left: 24px
            
            .phone
                color: #333
                font-weight: 600
                background: url('/imgs/phone.svg') left center no-repeat
                background-size: 18px

            .email
                color: #0A771B
                background: url('/imgs/email.svg') left center no-repeat
                background-size: 16px


        .messengers
            display: flex
            margin-top: 16px
            gap: 40px

            img, svg
                width: 40px
                height: 40px
                fill: #333

        .user
            display: flex
            flex-direction: column
            gap: 16px
            width: 375px
            max-width: 100%

            .user-info
                font-size: 15px
                font-weight: 600
                line-height: 150%
                color: #333

            .btn
                width: 100%
                padding: 7px 4px
                font-weight: 400
                line-height: 25px