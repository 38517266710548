header
    display: flex
    justify-content: space-between
    align-self: stretch
    align-items: center
    margin-bottom: 24px
    padding: 18px 32px
    border-radius: 8px
    background: white
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.05)

    .contacts
        display: flex
        flex-direction: column
        gap: 4px

        .phone, .email
            font-size: 15px
            line-height: 25px
            padding-left: 24px
        
        .phone
            color: #333
            font-weight: 600
            background: url('/imgs/phone.svg') left center no-repeat
            background-size: 18px

        .email
            color: #0A771B
            background: url('/imgs/email.svg') left center no-repeat
            background-size: 16px

    .messengers
        display: flex
        gap: 32px

        img, svg
            width: 40px
            height: 40px
            fill: #333

    .faq-btn
        display: flex
        align-items: center
        gap: 8px
        font-size: 15px
        line-height: 25px
        white-space: nowrap
        color: #0A771B
        cursor: pointer

        svg
            width: 24px
            height: 24px
            fill: #333
            stroke: #333
            transition: fill .2s, stroke .2s

        &:hover
            svg
                fill: #0A771B
                stroke: #0A771B

    .user
        display: flex
        gap: 16px
        align-items: center
        text-align: right

        .user-info
            font-size: 14px
            line-height: 140%

            .quit
                color: #0A771B
                cursor: pointer

                &:hover
                    text-decoration: underline

        img, svg
            width: 32px
            height: 32px
            stroke: #666

@media (max-width: 1550px)
    header
        .contacts
            .phone, .email
                font-size: 14px
        .messengers
            gap: 8px
        .faq-btn
            font-size: 14px
        .user
            .user-info
                font-size: 13px

@media (max-width: 1260px)
    header
        padding: 16px 24px
        .contacts
            .phone, .email
                font-size: 13px
        .faq-btn
            font-size: 13px
            white-space: wrap
            line-height: 140%
            span
                max-width: 60px
        .user
            .user-name
                display: block
                white-space: nowrap
                max-width: 100px
                text-overflow: ellipsis
                overflow: hidden

//Mobile version
.mob-header-wrapper
    position: fixed
    left: 0
    bottom: 0
    width: 100%
    z-index: 4
    display: flex
    flex-direction: column
    margin-top: 16px

    .filter
        padding: 16px var(--pad)
        margin: 0
        border-radius: 8px 8px 0 0
        background: white
        box-shadow: 0px -3px 4px 0px rgba(0, 0, 0, 0.05)
        max-width: 100%
        overflow-x: auto

        > *
            white-space: nowrap

.mob-header
    padding: 16px var(--pad) 24px var(--pad)
    border: 1px solid #EEE
    background: white

@media (min-width: 1021px)
    .mob-header
        display: none

@media (max-width: 1020px)
    header
        display: none