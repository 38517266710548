.project-modal
    --side-pad: 24px
    position: fixed
    top: 0
    right: 0
    height: 100vh
    width: 800px
    display: flex
    flex-direction: column
    color: #333
    background: white
    box-shadow: -24px 5px 64px 0px rgba(0, 0, 0, 0.10)
    z-index: 4
    visibility: hidden
    opacity: 0.5
    transform: translateX(100%)
    transition: transform .5s, opacity .5s, visibility 0s .5s

    &.showed
        visibility: visible
        opacity: 1
        transform: translateX(0)
        transition: transform .5s, opacity .5s

    .resizer
        position: absolute
        right: 100%
        top: 0
        width: 8px
        height: 100%
        background: #F8F8F8
        box-shadow: -24px 5px 64px 0px rgba(0, 0, 0, 0.10)
        cursor: col-resize

        &::after
            content: ''
            position: absolute
            top: 50%
            left: 50%
            width: 2px
            height: 36px
            margin-top: -18px
            margin-left: -1px
            border-radius: 2px
            background: #999
    
    .head
        display: flex
        justify-content: flex-end
        padding: 16px var(--side-pad)
        border-bottom: 1px solid #eee

        .close
            width: 24px
            height: 24px
            stroke: #666
            cursor: pointer

    .body
        display: flex
        flex-direction: column
        gap: 32px
        padding: 32px var(--side-pad)
        overflow-y: auto
    
    .project-props
        display: flex
        flex-direction: column
        gap: 8px
        font-size: 15px
        line-height: 25px

        .project-prop
            display: flex

        .field-name
            position: relative
            display: flex
            gap: 16px
            color: #555
            font-weight: 400
            width: 176px
            flex-shrink: 0
            max-width: 40%
            margin-right: 16px

            &::after
                content: ''
                height: 18px
                border-bottom: 1px solid #eee
                flex: 1
    
    .project-docs

        .text-title
            margin-bottom: 16px

        .project-doc
            display: flex
            flex-wrap: wrap
            gap: 16px
            
        .download
            display: none

        .file
            display: inline-flex
            gap: 8px
            align-items: center
            font-size: 15px

    .project-text

        .text-title
            margin-bottom: 8px

        .text-content
            font-size: 14px
            line-height: 140%
            color: #555

.confirm-modal

    .modal
        --modal-pad: 32px
        z-index: 5

        .modal-footer
            position: relative

            .loading
                margin: 0

                .loader
                    position: absolute
                    left: var(--modal-pad)
                    transform: translateY(-50%)
                    width: 32px

            .btn
                --bs-btn-padding-x: 23px
                --bs-btn-padding-y: 7px

@media (max-width: 1550px)
    .project-modal

        .body
            gap: var(--side-pad)
    
        .project-props
            font-size: 14px

        .project-docs
            .file
                font-size: 14px

        .project-text
            .text-content
                font-size: 13px

//Mobile version
.project-modal
    .mob-head
        position: relative
        padding: 17px var(--side-pad)

        &::after
            content: ''
            position: absolute
            top: 50%
            left: 50%
            width: 36px
            height: 2px
            margin-top: -1px
            margin-left: -18px
            border-radius: 2px
            background: #999


@media (min-width: 1021px)
    .project-modal
        .mob-head
            display: none

@media (max-width: 1020px)
    .project-modal
        --side-pad: 40px
        width: 100vw
        min-width: 100vw
        max-width: 100vw
        top: 32px
        height: calc(100vh - 32px)
        border-radius: 8px 8px 0 0
        transform: translateY(100%)
        &.showed
            transform: translateY(0)
        .resizer
            display: none
        .head
            display: none
        .body
            padding-top: 16px
            padding-bottom: 16px
            gap: 16px

        .project-name
            font-size: 18px
    
        .project-props
            font-size: 13px

            .field-name
                margin-right: 8px
                gap: 8px
        
        .project-docs
            .text-title
                font-size: 15px
            .file
                font-size: 13px
            .download
                display: block
                padding: 4px
                border-radius: 8px
                background: #F8F8F8

        .project-text
            .text-title
                font-size: 15px
            .text-content
                font-size: 12px

    .confirm-modal
        .modal
            .modal-body
                font-size: 13px

@media (max-width: 575px)
    .project-modal
        --side-pad: 16px
        
        .project-props
            .field-name
                width: 95px
