.catalog
    position: relative
    margin-top: -16px
    padding-bottom: calc(49px + 16px)
    background: var(--bg-color)

    &.loading-status, &.empty-status
        flex: 1

    .loading
        .loader
            position: fixed
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)

    .row
        flex-wrap: nowrap

        .col-auto
            padding-left: 16px
            padding-right: 16px
            border-right: 1px solid #eee
            font-size: 15px
            line-height: 25px
            color: #333

            &:last-child
                border-right: none
                flex: 1 0 0

            &:nth-child(1)
                width: 202px

            &:nth-child(2)
                width: 272px

            &:nth-child(3)
                width: 182px

            &:nth-child(4)
                width: 202px

.catalog-head
    --prow: calc(var(--bs-gutter-x) * .5)
    width: calc(100% + var(--prow) * 2)
    background: #f8f8f8
    padding-bottom: 8px
    margin-bottom: 8px
    padding-left: var(--prow)
    margin-left: calc(-.5 * var(--bs-gutter-x))
    padding-top: 16px
    z-index: 1

    &.abs-top
        position: absolute
        top: 0
        width: 100%

    &.fixed
        position: fixed
        top: 0
        width: calc(100% - var(--pad) * 2)
        left: var(--pad)
        margin-left: 0

.catalog-footer
    position: fixed
    left: 0
    bottom: 0
    width: 100%
    padding: 13px var(--pad) 31px
    display: flex
    z-index: 1
    
    &::before
        content: ''
        position: absolute
        height: 100%
        width: 100%
        left: 0
        top: 0
        background: linear-gradient(180deg, rgba(248, 248, 248, 0) 0%, rgba(248, 248, 248, 0.5) 15%, rgba(248, 248, 248, 0.8) 70%, rgba(248, 248, 248, 0.97) 100%)

    .once-count
        margin-left: auto

.pagination, .once-count
    position: relative
    display: flex
    padding: 4px
    gap: 4px
    border-radius: 8px
    background: white
    box-shadow: 0px 7px 17px 0px rgba(0, 0, 0, 0.08)


.filter
    display: flex
    align-items: center
    gap: 8px
    margin-bottom: 24px
    margin-left: calc(-.5 * var(--bs-gutter-x))

.fields

    .col-auto
        font-size: 15px
        line-height: 25px
        color: #555
        min-height: 32px

        span
            display: inline-flex
            gap: 16px
            align-items: center

        .sort-icon
            padding: 4px
            border-radius: 4px
            cursor: pointer
            transition: background .2s

            &:hover, &.active
                background: #eee

            svg
                width: 24px
                height: 24px
                stroke: #999
                fill: none

.projects
    position: relative
    display: flex
    flex-direction: column
    align-self: stretch
    gap: 16px

    .data-row
        padding: 16px 0
        border-radius: 8px
        background: white
        box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.05)
        cursor: pointer

        &:hover, &.active
            box-shadow: 0px 7px 17px 0px rgba(0, 0, 0, 0.08)

        .col-auto
            display: flex
            align-items: center
            flex-wrap: wrap
                
        .prop-budget
            font-weight: 600

.empty-projects
    position: absolute
    width: 100%
    height: 100%
    top: 0
    left: 0
    display: flex
    align-items: center
    justify-content: center

    img
        width: 60%
        max-width: 400px

@media (max-width: 1550px)
    .catalog
        .row
            .col-auto
                font-size: 14px

@media (max-width: 1260px)
    .catalog
        .row
            .col-auto
                &:nth-child(1)
                    width: 150px
                &:nth-child(2)
                    width: 202px
                &:nth-child(3)
                    width: 135px
                &:nth-child(4)
                    width: 170px

//Mobile version
@media (min-width: 1021px)
    .projects
        .data-row
            .field-name
                display: none

@media (max-width: 1020px)
    .catalog-head, .catalog-footer
        display: none
    
    .catalog
        margin-top: 0
        padding-bottom: 0

    .projects
        gap: 8px
        
        .data-row
            flex-direction: column
            gap: 8px

            .col-auto
                display: flex
                width: auto !important
                font-size: 13px
                border-right: none
                flex-wrap: nowrap
                align-items: flex-start

            .prop-name
                order: -1
                font-weight: 600

            .prop-name, .prop-status
                .field-name
                    display: none
            
            .field-name
                position: relative
                display: flex
                gap: 8px
                color: #555
                font-weight: 400
                width: 170px
                flex-shrink: 0
                max-width: 50%
                margin-right: 8px

                &::after
                    content: ''
                    height: 18px
                    border-bottom: 1px solid #eee
                    flex: 1

@media (max-width: 575px)
    .projects
        .data-row
            .field-name
                width: 103px