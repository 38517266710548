.auth
    position: relative
    display: flex
    flex-direction: column
    gap: 16px
    padding: 48px
    max-width: 88%
    width: 575px

    .text
        a
            font-weight: 700

@media (max-width: 1280px)
    .auth
        .text
            br
                display: none
            a
                white-space: nowrap

@media (max-width: 991px)
    .auth-page
        padding: 0

        .row
            margin: 0

            > *
                padding: 0

        .screen
            flex-direction: column

            .center:nth-child(2)
                flex: 1
                align-items: stretch

            .logo
                width: 100%
                padding: 32px 40px 24px

    .auth
        width: 100%
        max-width: 100%
        padding: 16px 40px

@media (max-width: 768px)
    .auth

        .form-buttons
            padding-top: 16px
            flex-direction: column
            align-items: stretch
            gap: 16px



@media (max-width: 575px)
    .auth-page
        
        .screen

            .logo
                padding-left: 16px
                padding-right: 16px
    
    .auth
        padding: 16px