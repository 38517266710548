.accordion
    display: flex
    flex-direction: column
    gap: 4px
    
    .accordion-item
        position: relative
        background: white
        border: 1px solid #EEE
        border-radius: 4px
        transition: box-shadow .3s

        &.showed, &:hover
            box-shadow: 0px 7px 17px 0px rgba(0, 0, 0, 0.08)
            z-index: 1

            .accordion-title
                color: #468847

        .accordion-header
            display: flex
            justify-content: space-between
            align-items: center
            padding: 16px
            cursor: pointer

        .accordion-title
            font-size: 15px
            font-weight: 600
            line-height: 21px
            color: #333
            transition: color .3s

        .accordion-close
            width: 24px
            height: 24px
            background: url('/imgs/plus.svg') center no-repeat
            background-size: 100%

        .accordion-body
            font-size: 15px
            line-height: 25px
            color: #555
            padding: 0 16px 16px

            p
                margin: 0

        .accordion-collapse
            position: absolute
            visibility: hidden
            overflow: hidden
        
            &.hidden
                height: 0 !important

        &.showed

            .accordion-close
                background: url('/imgs/close-thin.svg') center no-repeat
                background-size: 100%
            
            .accordion-collapse
                position: static
                visibility: visible
                transition: height .4s

.accordion-fade-enter-active, .accordion-fade-leave-active
    transition: height .3s

.accordion-fade-enter, .accordion-fade-leave-to
    height: 0px
    visibility: hidden

@media (max-width: 1280px)
    .accordion
        .accordion-item
            .accordion-title
                font-size: 14px
            .accordion-body
                font-size: 14px

//Mobile version
@media (max-width: 1020px)
    .accordion
        .accordion-item
            .accordion-title
                font-size: 13px
            .accordion-body
                font-size: 13px