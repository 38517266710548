:root
    --bs-body-font-family: Inter,system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"
    --bg-color: #F8F8F8
    
body
    background: var(--bg-color)

    &.no-select
        user-select: none

@media (min-width: 1021px)
    :not(.normal-scroll)::-webkit-scrollbar
        width: 10px

    :not(.normal-scroll)::-webkit-scrollbar-track
        background-color: #DFF0D8

    :not(.normal-scroll)::-webkit-scrollbar-thumb
        background-color: rgba(82, 149, 23, .7)
        background-clip: content-box
        border: 2px solid transparent
        border-radius: 5px

h1, h2, h3, h4, h5
    line-height: 150%
    font-weight: 600
    margin: 0

h1
    font-size: 40px

h2
    font-size: 30px

h3
    font-size: 26px

h4
    font-size: 20px

h5
    font-size: 18px

.text, .form-label
    font-size: 15px
    line-height: 25px
    margin: 0

@media (max-width: 1280px)
    h1
        font-size: 36px
    h2
        font-size: 28px
    h3
        font-size: 24px
    h4
        font-size: 18px
    h5
        font-size: 16px
    .text
        font-size: 14px

@media (max-width: 768px)
    h2
        font-size: 24px
    h3
        font-size: 18px
    h4
        font-size: 16px
    h5
        font-size: 15px
    .text
        font-size: 13px

.screen
    display: flex
    min-height: 100vh

.center
    display: flex
    align-items: center
    justify-content: center

.white-block
    border-radius: 4px
    background: white
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.05)

a
    color: #0A771B
    text-decoration: none

    &:hover
        color: #468847
        text-decoration: underline

.btn
    --bs-btn-padding-x: 16px
    --bs-btn-padding-y: 16px
    --bs-btn-font-size: 14px
    --bs-btn-font-weight: 700
    --bs-btn-line-height: 150%
    text-decoration: none !important

    &.btn-link
        --bs-btn-padding-x: 14px
        --bs-btn-padding-y: 7px
        --bs-btn-font-size: 15px
        --bs-btn-font-weight: 600
        --bs-btn-line-height: 25px

    &:focus, &:focus-visible
        box-shadow: none

.btn.btn-primary
    --bs-btn-color: white
    --bs-btn-bg: #0A771B
    --bs-btn-border-color: #0A771B
    --bs-btn-hover-color: white
    --bs-btn-hover-bg: #468847
    --bs-btn-hover-border-color: #468847
    --bs-btn-active-color: white
    --bs-btn-active-bg: #468847
    --bs-btn-active-border-color: #468847
    --bs-btn-disabled-color: white
    --bs-btn-disabled-bg: #5c945d
    --bs-btn-disabled-border-color: #5c945d

.btn.btn-secondary
    --bs-btn-color: #0A771B
    --bs-btn-bg: white
    --bs-btn-border-color: #D6E9C6
    --bs-btn-hover-color: #0A771B
    --bs-btn-hover-bg: #DFF0D8
    --bs-btn-hover-border-color: #DFF0D8
    --bs-btn-active-color: #0A771B
    --bs-btn-active-bg: #DFF0D8
    --bs-btn-active-border-color: #DFF0D8
    --bs-btn-disabled-color: #D6E9C6
    --bs-btn-disabled-bg: white
    --bs-btn-disabled-border-color: #D6E9C6

.btn.btn-light
    --bs-btn-color: #555
    --bs-btn-bg: #F8F8F8
    --bs-btn-border-color: #F8F8F8
    --bs-btn-hover-color: #555
    --bs-btn-hover-bg: #eee
    --bs-btn-hover-border-color: #eee
    --bs-btn-active-color: #555
    --bs-btn-active-bg: #eee
    --bs-btn-active-border-color: #eee
    --bs-btn-disabled-color: #777
    --bs-btn-disabled-bg: #F8F8F8
    --bs-btn-disabled-border-color: #F8F8F8

.btn.btn-link
    --bs-btn-color: #555
    --bs-btn-bg: transparent
    --bs-btn-border-color: transparent
    --bs-btn-hover-color: #555
    --bs-btn-hover-bg: white
    --bs-btn-hover-border-color: white
    --bs-btn-active-color: #555
    --bs-btn-active-bg: white
    --bs-btn-active-border-color: white
    --bs-btn-active-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.05)
    --bs-btn-disabled-color: #777
    --bs-btn-disabled-bg: transparent
    --bs-btn-disabled-border-color: transparent

.content-page
    --pad: 80px
    display: flex
    flex-direction: column
    padding: 8px var(--pad)
    min-height: 100vh

.content
    display: flex
    flex-direction: column
    gap:24px
    flex: 1
    padding-bottom: 24px

    .title
        display: flex
        align-items: center
        justify-content: space-between

.tabs
    display: flex
    padding: 4px
    align-items: center
    gap: 4px
    border-radius: 8px
    background: #EEE

.tag
    position: relative
    display: flex
    align-items: center
    padding: 3px 7px
    gap: 8px
    border-radius: 8px
    border: 1px solid #EEE
    background: #EEE
    color: #555
    font-size: 15px
    line-height: 25px
    cursor: pointer
    transition: background .2s, border-color .2s, box-shadow .2s

    &:hover
        background: white
        border-color: white
        box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.05)

    svg
        width: 16px
        height: 16px
        stroke: #999

        &.reset
            width: 24px
            height: 24px

    &.clear
        gap: 4px
        background: #F8F8F8
        border-color: #F8F8F8
        
        &:hover
            background: #eee
            border-color: #eee
            box-shadow: none

.pagi-btn
    padding: 8px 16px
    background: white
    border-radius: 4px
    font-size: 15px
    font-weight: 600
    line-height: 25px
    text-align: center
    text-decoration: none
    cursor: pointer
    transition: background .2s

    &.active, &:hover
        background: #eee

.badge
    --bs-badge-padding-x: 8px
    --bs-badge-padding-y: 4px
    --bs-badge-font-size: 14px
    --bs-badge-font-weight: 400
    --bs-badge-color: #fff
    --bs-badge-border-radius: 4px
    line-height: 140%

    &.new, &.participation
        color: #468847
        background: #DFF0D8
    &.watched, &.finished, &.unknown
        color: #999
        background: #F8F8F8
    &.moderation
        color: #C78B23
        background: #FFF9D9
    &.canceled
        color: #E83333
        background: #FFEEEE

.whatsapp
    svg
        &:hover
            fill: #25D366

.telegram
    svg
        &:hover
            fill: #2AABEE

.viber
    svg
        &:hover
            fill: #8f5db7

.nav-item
    display: flex
    flex-direction: column
    align-items: center
    gap: 4px
    font-size: 12px
    line-height: 140%
    color: #999
    cursor: pointer

    svg
        width: 24px
        height: 24px
        fill: none
        stroke: #666

    &.active, &:hover
        color: #333
        svg
            stroke: #468847

.gray-alert
    display: flex
    flex-direction: column
    border-radius: 8px
    background: #F8F8F8
    padding: 16px
    gap: 16px

    .alert-body
        font-size: 14px
        line-height: 140%
        color: #555

        p
            margin: 0

        .alert-title
            font-size: 15px
            font-weight: 600
            line-height: 25px
            color: #468847
            margin-bottom: 4px

    .alert-foot

        .btn
            --bs-btn-padding-x: 23px
            --bs-btn-padding-y: 7px
            --bs-btn-font-size: 14px

.shadow
    position: fixed
    top: 0
    left: 0
    width: 100vw
    height: 100vh
    background: rgba(0,0,0,.3)
    visibility: hidden
    opacity: 0
    z-index: 3
    transition: opacity .3s, visible 0s .3s

    &.showed
        opacity: 1
        visibility: visible
        transition: opacity .3s, visible 0s

.modal
    --modal-pad: 40px
    background: rgba(12,12,12,.2)
    display: block
    z-index: 3

    .modal-content
        border: none

    .modal-dialog
        border-radius: 8px
        background: white
        box-shadow: 0px 7px 17px 0px rgba(0, 0, 0, 0.08)

    .modal-mob-header
        display: none

    .modal-header
        padding: 40px 32px
        border-bottom: none

        .btn-close
            width: 24px
            height: 24px
            opacity: 1
            background: url('/imgs/close-thin.svg') center no-repeat
            background-size: 100%
            padding: 0
            margin: 0
            box-shadow: none

    .modal-title
        letter-spacing: 0.4px
        line-height: 21px

    .modal-body
        padding: 0 var(--modal-pad) var(--modal-pad)

.modal-fade-enter-active, .modal-fade-leave-active
    transition: opacity .3s

    .modal-dialog
        transition: transform .3s

.modal-fade-enter, .modal-fade-leave-to
    opacity: 0

    .modal-dialog
        transform: translateY(50px)

.list-checkbox
    display: flex
    flex-direction: column
    gap: 16px

    .form-check
        display: flex
        padding: 0
        margin: 0
        min-height: auto
        gap: 8px
        align-items: center

        .form-check-input
            position: relative
            float: none
            margin: 0

    label
        font-size: 15px
        line-height: 25px
        user-select: none
        color: #555

.form-check-input
    --bs-form-check-bg: #F8F8F8
    --bs-border-color: #eee
    width: 24px
    height: 24px
    margin: 0
    border-radius: 4px
    box-shadow: none

    &:focus, &:checked
        box-shadow: none
        border-color: #eee

    &:checked
        background-color: #F8F8F8
        border-color: #eee
        --bs-form-check-bg-image: url('/imgs/checked.svg') !important
        background-size: 16px

.filter-enter-active, .filter-leave-active
    transition: opacity .3s, transform .3s

.filter-enter, .filter-leave-to
    position: absolute
    opacity: 0
    transform: translateX(50px)

.filter-leave-to
    transform: translateX(0px)

.fade-enter-active, .fade-leave-active
    transition: opacity .3s

.fade-enter, .fade-leave-to
    opacity: 0

.modal-faq

    .modal-body
        min-height: 100px

.status-message
    --color: var(--bs-primary)
    display: inline-flex
    width: 21px
    height: 21px
    background-color: var(--color)

    &.status-success
        --color: var(--bs-success)

    &.status-warning
        --color: var(--bs-warning)
        
    &.status-danger
        --color: var(--bs-danger)

.toast
    border: none

    .toast-header
        --color: var(--bs-primary)
        color: var(--color)

        &.status-success
            --color: var(--bs-success)

        &.status-warning
            --color: var(--bs-warning)
            
        &.status-danger
            --color: var(--bs-danger)

.loader
    width: 40px
    aspect-ratio: 1
    display: grid

.loader::before, .loader::after
    content: ""
    grid-area: 1/1
    --c:no-repeat linear-gradient(#0A771B 0 0)
    background: var(--c) 0 0, var(--c) 100% 0, var(--c) 100% 100%, var(--c) 0 100%
    animation: l10-1 1.5s infinite linear, l10-2 1.5s infinite linear

.loader::after
    margin: 25%
    transform: scale(-1)

@keyframes l10-1
    0%   
        background-size: 0    4px,4px 0   ,0    4px,4px 0
    12.5%
        background-size: 100% 4px,4px 0   ,0    4px,4px 0
    25%  
        background-size: 100% 4px,4px 100%,0    4px,4px 0
    37.5%
        background-size: 100% 4px,4px 100%,100% 4px,4px 0
    45%, 55%  
        background-size: 100% 4px,4px 100%,100% 4px,4px 100%
    62.5%
        background-size: 0    4px,4px 100%,100% 4px,4px 100%
    75%  
        background-size: 0    4px,4px 0   ,100% 4px,4px 100%
    87.5%
        background-size: 0    4px,4px 0   ,0    4px,4px 100%
    100% 
        background-size: 0    4px,4px 0   ,0    4px,4px 0   

@keyframes l10-2
    0%, 49.9%
        background-position: 0 0   ,100% 0   ,100% 100%,0 100%
    50%, 100%
        background-position: 100% 0,100% 100%,0    100%,0 0

.loading
    position: absolute
    z-index: 99
    top: 0
    left: 0
    width: 100%
    height: 100%
    display: flex
    align-items: center
    justify-content: center

    &::before
        content: ''
        position: absolute
        width: 100%
        height: 100%
        top: 0
        left: 0
        background-color: var(--bg-color)
        opacity: .5

    .loader
        opacity: .7

.loader-2
    --space: 12px
    width: 6px
    aspect-ratio: 1
    border-radius: 50%
    animation: l5 1s infinite linear alternate

@keyframes l5
    0%   
        box-shadow: var(--space) 0 #000, calc(-1 * var(--space)) 0 #0002
        background: #000 
    33%  
        box-shadow: var(--space) 0 #000, calc(-1 * var(--space)) 0 #0002
        background: #0002
    66%  
        box-shadow: var(--space) 0 #0002,calc(-1 * var(--space)) 0 #000
        background: #0002
    100% 
        box-shadow: var(--space) 0 #0002,calc(-1 * var(--space)) 0 #000
        background: #000

.mob-loader
    display: flex
    height: 47px
    align-items: center
    justify-content: center
    width: 100%

@media (max-width: 1550px)
    .content-page
        --pad: 40px

    .btn
        &.btn-link
            --bs-btn-font-size: 14px

    .tag
        font-size: 14px

    .badge
        --bs-badge-font-size: 13px
    
    .pagi-btn
        font-size: 14px

    .gray-alert
        .alert-body
            font-size: 13px
            .alert-title
                font-size: 14px
        .alert-foot
            .btn
                --bs-btn-font-size: 13px

    .list-checkbox
        label
            font-size: 14px

@media (max-width: 1280px)
    .btn
        --bs-btn-font-size: 13px

@media (max-width: 1260px)
    .content-page
        --pad: 30px

//Mobile version
.filter-show-btn
    --bs-btn-padding-x: 4px
    --bs-btn-padding-y: 3px
    width: 100%
    font-size: 13px
    font-weight: 400
    line-height: 25px

    svg
        width: 24px
        height: 24px
        fill: #333

@mixin mobScroll
    display: flex
    max-width: calc(100% + 2 * var(--pad))
    margin: 0 calc(-1 * var(--pad)) -10px
    padding: 0 var(--pad) 10px
    overflow-x: auto

    > *
        white-space: nowrap

@media (min-width: 1021px)
    .content
        .title
            .filter-show-btn
                display: none

@media (max-width: 1020px)
    .content-page
        --pad: 40px
        padding-top: 0
        padding-bottom: 0
        overflow-x: hidden

    .content
        padding-bottom: 0

        .title
            position: fixed
            top: 0
            left: 0
            width: 100%
            z-index: 2
            display: flex
            padding: 16px var(--pad)
            margin-bottom: 16px
            flex-direction: column
            align-items: flex-start
            gap: 16px
            align-self: stretch
            border-radius: 0 0 8px 8px
            border: 1px solid #EEE
            background: white
            box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.05)

            h2
                font-size: 24px

            .filter-show-btn
                display: block

        .projects-category
            @include mobScroll

        .btn-link
            --bs-btn-padding-x: 7px
            --bs-btn-padding-y: 3px
            --bs-btn-font-size: 13px

    .tag
        font-size: 13px

    .badge
        --bs-badge-font-size: 12px

    .gray-alert
        .alert-body
            font-size: 12px
            .alert-title
                font-size: 13px
        .alert-foot
            .btn
                width: 100%

    .modal
        display: flex
        padding-top: 32px

        .modal-dialog
            margin: auto 0 0
            width: 100%
            max-width: 100%
            max-height: calc(100% + 8px)
            margin-bottom: -8px
            padding-bottom: 8px
            border-radius: 8px 8px 0 0
        .modal-content
            height: 100%
            max-height: 100%
            overflow-y: hidden
        .modal-body
            overflow-y: auto
        .modal-mob-header
            display: block
            position: relative
            padding: 17px
            &::after
                content: ""
                position: absolute
                top: 50%
                left: 50%
                width: 36px
                height: 2px
                margin-top: -1px
                margin-left: -18px
                border-radius: 2px
                background: #999
        .modal-header
            padding: 16px var(--modal-pad) 24px
            .modal-title
                font-size: 16px
                line-height: 150%
                letter-spacing: 0.32px
            .btn-close
                display: none

    .modal-fade-enter-active, .modal-fade-leave-active
        transition: opacity .3s

        .modal-dialog
            transition: transform .3s

    .modal-fade-enter, .modal-fade-leave-to
        opacity: 0

        .modal-dialog
            transform: translateY(100%)

@media (max-width: 575px)
    .content-page
        --pad: 16px
    
    .modal
        --modal-pad: 16px